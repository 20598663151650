import { useEffect, useState } from 'react'
import './App.css';

import { CopyToClipboard } from 'react-copy-to-clipboard';


import { Chessboard } from "react-chessboard";

const dataset = require("./dataset.json");


function App() {


  const [index, setIndex] = useState(Math.floor(Math.random() * 1000));
  const [streak, setStreak] = useState(0);
  const [over, setOver] = useState(false);

  var width = 100;

  const check = (white) => {
    if (dataset[index]["eval"] > 0) {
      if (white) {
        setStreak(streak + 1);
      } else {
        setOver(true);
      }
    } else if (dataset[index]["eval"] < 0) {
      if (!white) {
        setStreak(streak + 1);
      } else {
        setOver(true);
      }
    } else {
      setStreak(streak + 1);
    }
    setIndex(Math.floor(Math.random() * 1000));
  }

  const loading = async () => {
    const progressBar = document.querySelector('#progress-bar');
    if (progressBar) {
      const interval = setInterval(() => {
        if (width <= 0) {
          setOver(true);
          return;
        }
        if (!over) {
          width = (width - 1);

          progressBar.style.background = `#6a9b41`;
          if (width <= 20) {
            progressBar.style.background = `#ff8585`;
          }
          progressBar.style.width = `${width}%`;
        }
      }, 2000);
    }
  }

  useEffect(() => {
    loading();
  }, []);

  return (
    <>
      <div className='flex p-2'>
        <a className='m-auto click' href="https://www.notchess.xyz"><img src="logo.png" width={150}></img></a>
      </div>


      <div className='h-[1px] w-[100%] bg-[#4c4c4c]'></div>
      <br></br><br></br>
      {!over &&
        <div>
          <p className='justify-center text-center'>
            <p>The position was analysed by an AI (Stockfish) at Depth-22. Can you guess who is winning?</p>
            <br></br>
          </p>
          <div className='flex'>
            <div className='m-auto border-2 border-[#282828] visible'>
              <Chessboard
                id="standard"
                className="drop-shadow-5xl"
                orientation="black"
                boardWidth={300}

                arePiecesDraggable={false}

                customDarkSquareStyle={{ background: "#6a9b41" }}
                customLightSquareStyle={{ background: "#f3f3f4" }}

                boardOrientation={dataset[index]["fen"].split(" ")[1] == "w" ? "white" : "black"}
                position={dataset[index]["fen"]}
              />
            </div>
          </div>
          <br></br>
          <p className='justify-center text-center'>
            {dataset[index]["fen"].split(" ")[1] == "w" ? <p>It is white's turn</p> : <p>It is black's turn</p>}
            <br></br>
          </p>
          <div>
            <div className='flex justify-center text-center'>
              <div className="border-2 border-[#282828] float-right mx-5 drop-shadow-5xl bg-black rounded-xl bg-[#f3f3f4]">
                <button className='px-5 py-5' onClick={() => check(true)}>White is winning ♕</button>
              </div>
              <div className="border-2 border-[#282828] float-left mx-5 drop-shadow-5xl bg-black rounded-xl">
                <button className='px-5 py-5' onClick={() => check(false)}>Black is winning ♕</button>
              </div>
            </div>
          </div>
          <p className='block justify-center text-center'>
            <br></br>
            <p>Score: 🔥{streak} </p>
          </p>
        </div>
      }
      {over &&
        <div>
          <div className='flex'>
            <div className='m-auto text-center'>
              <img src="party-parrot-emoji.gif" width="300px" className='m-auto'></img>

              <br></br><br></br>
              <p>Whoops! Game Over.</p>
              <p>You either guessed wrong or ran out of time.</p>
              <br></br>
              <p>Score: 🔥{streak}</p>
              <br></br><br></br>
              <p className='click'>
                <CopyToClipboard text={`✷ NotChess ✷ by NotPink

Streak: 🔥 ${streak}

Can you beat my streak?

https://notchess.xyz`}
                  onCopy={() => alert("Copied to clipboard.")}>
                  <a className='p-5 border-2 border-[#282828] rounded-xl w-[100%] mt-5 bg-[#f3f3f4] text-black'>Copy to Clipboard 📋</a >
                </CopyToClipboard>
              </p>
              <br></br><br></br>
              <p className='click'>
                <a target="_blank" href={`https://twitter.com/intent/tweet?text=✷ NotChess ✷ by @notpinkxyz%0A%0AStreak: 🔥 ${streak}%0A%0ACan you beat my streak?%0A%0Ahttps://notchess.xyz`} className='p-5 border-2 border-[#282828] rounded-xl w-[100%] mt-5 bg-blue-500 text-white'>Share on Twitter 🐦</a>
              </p>
              <br></br><br></br>
            </div>
          </div>
        </div>
      }

      <div className={!over ? 'visible' : 'hidden'}>
        <br></br>
        <div className='h-3 w-[300px] bg-[#f3f3f4] m-auto rounded-xl'>
          <div id="progress-bar" className='h-3 bg-[#6a9b41] rounded-xl'></div>
        </div>
        <br></br>
        <br></br>
      </div>
    </>

  );
}

export default App;
